.comment-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

.nickname-input,
.comment-input {
  width: 80%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #D1D1D1;
  border-radius: 5px;
  font-size: 14px;
}

.comment-button {
  background-color: #66B88C;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  margin-left: auto;
}

.comment-button:hover {
  background-color: #7BC7A3;
}

.comment-note {
  font-size: 12px;
  color: #333333;
  margin-top: 10px;
  text-align: left;
}

.comment-note .link-button {
  background: none;
  border: none;
  color: #F4A22C;
  cursor: pointer;
  text-decoration: underline;
  padding: 0;
  font: inherit;
}

.comment-note .link-button:hover {
  color: #F6B55A;
}

@media (max-width: 768px) {
  .comment-form {
    width: 100%;
    padding: 12px;
    max-width: none;
  }
}
