.card-list {
  border: 1px solid #ddd; /* グレーの枠線 */
  border-radius: 8px; /* 丸みを帯びた角 */
  padding: 16px;
  margin: 8px 0; /* 投稿ごとの上下の隙間 */
  cursor: pointer;
  transition: box-shadow 0.3s;
  background-color: #FFFFFF; /* 白背景に戻す */
  width: 100%;
  box-sizing: border-box;
}

.card-list:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.card-header-right {
  display: flex;
  align-items: center;
  gap: 10px;
}

.card-info {
  display: flex;
  align-items: center;
  gap: 10px;
}

.card-icon {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.card-author {
  font-size: var(--text-size-medium);
  color: var(--text-color-primary);
  font-weight: bold;
}

.card-category {
  padding: 5px 15px;
  color: var(--primary-color) !important;
  border: 1px solid var(--primary-color);
  border-radius: 20px;
}

.card-title {
  font-size: 18px;
  margin: 8px 0;
  color: var(--primary-color);
  font-weight: bold;
}

.card-content {
  color: var(--text-color);
  white-space: pre-line;
}

.card-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}

.left-actions {
  display: flex;
  gap: 16px;
}

.action-info {
  display: flex;
  align-items: center;
  gap: 4px;
  color: #66B88C;
}

.action-icon {
  font-size: 18px;
}

.action-text {
  color: #66B88C;
}

.read-more-button {
  font-size: 14px;
  color: var(--primary-color);
  transition: color 0.3s;
}

.read-more-button:hover {
  color: var(--hover-color-primary);
}

@media (max-width: 768px) {
  .card-list {
    padding: 12px;
    width: 100%;
    max-width: none;
  }
}