:root {
  /* Colors */
  --primary-color: #66B88C;
  --secondary-color: #5F9EA0;
  --background-color: #FFFAF0;
  --text-color: #333333;
  --light-text-color: #666666;
  --border-color: #E1E8ED;
  --card-background: #FFFFFF;
  --hover-color: #FFFAF0;
  --hover-color-primary: #7BC7A3;
  --hover-color-secondary: #6FB3B5;

  /* Typography */
  --font-family: 'Noto Sans JP', sans-serif;
  --heading-large: 2.5rem;
  --heading-medium: 2rem;
  --heading-small: 1.5rem;
  --body-text: 1rem;
  --meta-text: 0.875rem;
}

body {
  font-family: var(--font-family);
  background-color: var(--hover-color); 
  color: var(--text-color);
  line-height: 1.6;
  margin: 0;
  padding: 0;
}

.thread-detail {
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem;
  background-color: var(--hover-color);
  border-radius: 8px;
}

.navigation {
  margin-bottom: 1rem;
}

.back-button {
  background: none;
  border: none;
  color: var(--primary-color);
  font-size: 1.5rem;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.back-button i {
  margin-right: 0.5rem;
}

.thread-card {
  background-color: var(--card-background);
  border: 1px solid var(--border-color);
  border-radius: 8px;
  padding: 16px;
  margin: 8px 0;
  transition: box-shadow 0.3s;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  width: 100%;
  box-sizing: border-box;
}

.thread-card:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.thread-title {
  font-size: var(--heading-large);
  color: var(--primary-color);
  margin-bottom: 1rem;
}

.thread-content {
  font-size: var(--body-text);
  line-height: 1.6;
  color: var(--text-color);
  margin-bottom: 1.5rem;
}

.thread-meta {
  display: flex;
  justify-content: space-between;
  font-size: var(--meta-text);
  color: var(--light-text-color);
  margin-bottom: 2rem;
}

.comments-section {
  margin-top: 2rem;
}

.comments-section h2 {
  font-size: var(--heading-medium);
  margin-bottom: 1rem;
  color: var (--text-color);
}

.comment-card {
  background-color: var(--card-background);
  border: 1px solid var(--border-color);
  border-radius: 8px;
  padding: 16px;
  margin: 8px 0;
  width: 100%;
  box-sizing: border-box;
}

.comment-card:hover {
  background-color: #E8F4F1;
}

.comment.level-0 {
  margin-left: 0;
}

.comment.level-1 {
  margin-left: 40px;
}

.comment-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
}

.user-info {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.comment-author {
  font-weight: bold;
  color: var(--text-color);
}

.comment-date {
  font-size: var(--meta-text);
  color: var(--light-text-color);
}

.comment-content {
  font-size: var(--body-text);
  line-height: 1.5;
  color: var(--text-color);
  margin-bottom: 0.5rem;
}

.comment-actions {
  display: flex;
  gap: 1rem;
}

.action-button {
  display: flex;
  align-items: center;
  gap: 0.3rem;
  background: none;
  border: 1px solid var(--primary-color);
  border-radius: 20px;
  color: var(--primary-color);
  cursor: pointer;
  padding: 8px 12px;
  transition: color 0.3s ease, background-color 0.3s ease;
}

.action-button:hover {
  background-color: var(--primary-color);
  color: white;
}

.loading {
  text-align: center;
  font-size: 1.2rem;
  color: var(--light-text-color);
  margin-top: 2rem;
}

.comment-form {
  background-color: var(--hover-color);
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 15px;
  margin-top: 20px;
}

.comment-form input,
.comment-form textarea {
  width: 100%;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 10px;
  margin-bottom: 10px;
  resize: vertical;
}

.comment-form .submit-button {
  background-color: var(--primary-color);
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.comment-form .submit-button:hover {
  background-color: var(--hover-color-primary);
}

.replying-to {
  background-color: #f0f0f0;
  border-radius: 4px;
  padding: 10px;
  margin-bottom: 10px;
  font-size: 0.9em;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.replying-to button {
  background: none;
  border: none;
  color: #666;
  cursor: pointer;
  text-decoration: underline;
}

.no-comments {
  text-align: center;
  padding: 20px;
  background-color: transparent;
  margin-top: 20px;
}

.no-comments img {
  max-width: 200px;
  margin-bottom: 20px;
}

.no-comments p {
  font-size: var(--body-text);
  color: var(--text-color);
  margin: 0;
}

@media (max-width: 768px) {
  .thread-detail {
    padding: 1rem;
    max-width: 100%;
  }

  .thread-title {
    font-size: var(--heading-medium);
  }

  .comment.level-1 {
    margin-left: 1rem;
  }
}