.thread-card {
  position: relative;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 16px;
  margin: 8px 0;
  background-color: var(--card-background);
  width: 100%;
  box-sizing: border-box;
  transition: box-shadow 0.3s;
}

.thread-card:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.thread-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.thread-info {
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
}

.thread-info img {
  border-radius: 50%;
}

.thread-author, .thread-date {
  font-size: var(--meta-text);
  color: var(--light-text-color);
}

.thread-title {
  font-size: 1.2rem;
  margin: 8px 0;
  color: var(--primary-color);
  font-weight: bold;
}

.thread-content {
  color: var(--text-color);
  cursor: pointer;
  white-space: pre-line;
  word-break: break-all;
}

.thread-actions {
  display: flex;
  gap: 1rem;
  padding-top: 10px;
  margin-top: 10px;
  border-top: 1px solid var(--border-color);
}

.action-button {
  display: flex;
  align-items: center;
  gap: 0.3rem;
  background: none;
  border: 1px solid var(--primary-color);
  border-radius: 20px;
  color: var(--primary-color);
  cursor: pointer;
  padding: 8px 12px;
  transition: color 0.3s ease, background-color 0.3s ease;
}

.action-button:hover {
  background-color: var(--primary-color);
  color: white;
}

.thread-report-flag {
  cursor: pointer;
  color: var(--primary-color);
  transition: color 0.3s;
}

.thread-report-flag:hover {
  color: var(--hover-color-primary);
}