.search-bar {
  display: flex;
  margin-bottom: 20px;
  max-width: 800px;  /* 横幅を制限 */
  margin: 0 auto 20px;  /* センタリング */
}

.search-bar input {
  flex-grow: 1;
  padding: 10px 15px;
  font-size: 1rem;
  border: 1px solid var(--border-color);
  border-radius: 20px 0 0 20px;
  outline: none;
  min-width: 0;  /* これにより入力フィールドが縮小されることを防止 */
}

.search-bar button {
  padding: 10px 20px;
  background-color: var(--primary-color);
  color: white;
  border: none;
  border-radius: 0 20px 20px 0;
  cursor: pointer;
  transition: background-color 0.3s;
  display: flex;
  align-items: center;
}

.search-bar button:hover {
  background-color: var(--hover-color-primary);
}

.search-bar .icon {
  margin-right: 8px;
}
