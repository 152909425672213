:root {
  /* Colors */
  --primary-color: #66B88C;
  --secondary-color: #5F9EA0;
  --background-color: #FFFAF0;
  --text-color: #333333;
  --light-text-color: #666666;
  --border-color: #E1E8ED;
  --card-background: #FFFFFF;
  --hover-color: #FFFAF0;
  --hover-color-primary: #7BC7A3;
  --hover-color-secondary: #6FB3B5;

  /* Typography */
  --font-family: 'Noto Sans JP', sans-serif;
  --heading-large: 2.5rem;
  --heading-medium: 2rem;
  --heading-small: 1.5rem;
  --body-text: 1rem;
  --meta-text: 0.875rem;
}

body {
  font-family: var(--font-family);
  background-color: var(--background-color);
  color: var(--text-color);
  line-height: 1.6;
  margin: 0;
  padding: 0;
}

.app {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  padding: 0;
  background-color: var(--background-color);
  box-sizing: border-box;
}

.app-header {
  background-color: var(--background-color);
  padding: 0px 0;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  margin-bottom: 0px;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 0px;
}

.navbar-brand {
  display: flex;
  align-items: center;
  font-size: 1.5rem;
  font-weight: 700;
  color: var(--primary-color);
}

.brand-icon {
  margin-right: 10px;
  color: var(--secondary-color);
}

.main-content {
  background-color: var(--background-color);
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  padding: 20px;
}

.tab-navigation {
  display: flex;
  margin-bottom: 20px;
  border-bottom: 2px solid var(--border-color);
}

.tab {
  padding: 10px 20px;
  font-size: 1rem;
  font-weight: 600;
  color: var(--light-text-color);
  background: none;
  border: none;
  cursor: pointer;
  transition: color 0.3s, border-bottom 0.3s;
}

.tab.active {
  color: var(--primary-color);
  border-bottom: 2px solid var(--primary-color);
}

.search-bar {
  display: flex;
  margin-bottom: 20px;
}

.search-bar input {
  flex-grow: 1;
  padding: 10px 15px;
  font-size: 1rem;
  border: 1px solid var(--border-color);
  border-radius: 20px 0 0 20px;
  outline: none;
}

.search-bar button {
  padding: 10px 20px;
  background-color: var(--primary-color);
  color: white;
  border: none;
  border-radius: 0 20px 20px 0;
  cursor: pointer;
  transition: background-color 0.3s;
}

.search-bar button:hover {
  background-color: darken(var(--primary-color), 10%);
}

.category-filter {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 20px;
}

.category {
  padding: 5px 15px;
  font-size: 0.9rem;
  color: var(--primary-color);
  background-color: var(--background-color);
  border: 1px solid var(--primary-color);
  border-radius: 20px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.category.active, .category:hover {
  background-color: var(--primary-color);
  color: white;
}

.thread-card {
  background-color: var(--card-background);
  border-radius: 8px;
  padding: 15px;
  margin-bottom: 15px;
  transition: box-shadow 0.3s;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.thread-card:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.thread-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  font-size: 0.9rem;
  color: var(--light-text-color);
}

.thread-author {
  display: flex;
  flex-direction: column;
}

.thread-date {
  font-size: 0.8rem;
  color: var(--light-text-color);
}

.thread-category {
  font-size: 0.9rem;
  color: var(--light-text-color);
  margin-left: auto;
}

.thread-body {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}

.thread-title {
  font-size: 1.2rem;
  color: var(--primary-color);
  margin-bottom: 10px;
}

.thread-content {
  margin-bottom: 10px;
}

.thread-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.left-actions {
  display: flex;
  align-items: center;
  gap: 10px;
}

.read-more-button {
  font-size: 0.9rem;
  color: var(--primary-color);
  background: none;
  border: none;
  cursor: pointer;
  text-decoration: underline;
  transition: color 0.3s;
}

.read-more-button:hover {
  color: var(--secondary-color);
}

.action-button {
  display: flex;
  align-items: center;
  padding: 8px 12px;
  font-size: 14px;
  color: var(--primary-color);
  background: none;
  border: 1px solid var(--primary-color);
  border-radius: 20px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.action-button:hover {
  background-color: var(--primary-color);
  color: white;
}

.comment-button {
  display: flex;
  align-items: center;
  gap: 4px;
  color: var(--primary-color);
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 10px;
  border-radius: 4px;
  font-size: var(--meta-text);
}

.comment-button:hover {
  background-color: var(--hover-color);
}

.post-form {
  background-color: var(--card-background);
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 20px;
}

.post-form h2 {
  font-size: 1.5rem;
  color: var(--primary-color);
  margin-bottom: 20px;
}

.post-form input, .post-form textarea {
  width: 100%;
  padding: 10px 15px;
  margin-bottom: 15px;
  font-size: 1rem;
  border: 1px solid var(--border-color);
  border-radius: 4px;
  outline: none;
}

.post-form textarea {
  min-height: 150px;
  resize: vertical;
}

.button-group {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.preview-button, .post-button {
  padding: 10px 20px;
  font-size: 1rem;
  color: white;
  background-color: var(--primary-color);
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.preview-button:hover, .post-button:hover {
  background-color: darken(var(--primary-color), 10%);
}

.preview {
  background-color: var(--card-background); /* 背景色を白に設定 */
  border-radius: 8px;
  padding: 20px;
  margin-top: 20px;
}

.thread-detail {
  background-color: var(--hover-color);
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 20px;
}

.thread-detail .thread-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  font-size: 0.9rem;
  color: var(--light-text-color);
}

.thread-detail .thread-author {
  font-weight: bold;
}

.thread-detail .thread-date {
  font-size: 0.8rem;
  color: var(--light-text-color);
}

.thread-detail .thread-title {
  font-size: 1.5rem;
  color: var(--primary-color);
  margin-bottom: 10px;
}

.thread-detail .thread-content {
  margin-bottom: 15px;
}

.thread-detail .thread-category {
  font-size: 0.9rem;
  color: var(--light-text-color);
  margin-bottom: 15px;
}

.thread-detail .thread-actions {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 15px;
  margin-bottom: 20px;
}

.comments-section {
  margin-top: 20px;
}

.comments-section h4 {
  margin-bottom: 10px;
}

.comment {
  background-color: var(--card-background); /* 背景色を白に設定 */
  border-radius: 8px;
  padding: 10px 15px;
  margin-bottom: 15px;
  transition: margin-left 0.3s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.comment-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  font-size: 0.9rem;
  color: var(--light-text-color);
}

.comment-author {
  font-weight: bold;
}

.comment-date {
  font-size: 0.8rem;
  color: var(--light-text-color);
}

.comment-content {
  margin-bottom: 10px;
  font-size: 1rem;
  color: var(--text-color);
}

.comment-actions {
  display: flex;
  justify-content: flex-start;
  gap: 10px;
}

.comment .action-button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  padding: 8px 12px;
  font-size: 0.9rem;
  color: var(--primary-color);
  background: none;
  border: 1px solid var(--primary-color);
  border-radius: 20px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  min-width: 90px;
}

.comment .action-button:hover {
  background-color: var(--primary-color);
  color: white;
}

.comment.level-0 { margin-left: 0; }
.comment.level-1 { margin-left: 40px; }

.comment + .comment {
  margin-top: 15px;
}

.comment-form {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

.comment-form textarea {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid var(--border-color);
  border-radius: 4px;
  resize: vertical;
  outline: none;
}

.comment-form .post-button {
  align-self: flex-end;
  padding: 10px 20px;
  background-color: var(--secondary-color);
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.comment-form .post-button:hover {
  background-color: darken(var(--secondary-color), 10%);
}

.replying-to {
  background-color: var(--hover-color);
  border-radius: 8px;
  padding: 10px;
  margin-bottom: 10px;
  font-size: 0.9rem;
  color: var(--light-text-color);
  display: flex;
  justify-content: space-between;
}

.replying-to p {
  margin: 0;
  font-weight: bold;
}

.replying-to button {
  background: none;
  border: none;
  color: var(--primary-color);
  cursor: pointer;
  text-decoration: underline;
  font-size: 0.9rem;
}

.replying-to button:hover {
  color: var(--secondary-color);
}

@media (max-width: 768px) {
  .app {
    padding: 0;
  }
}