body {
  margin: 0;
  font-family: Arial, sans-serif;
}

input, textarea, button {
  font-family: inherit;
  font-size: 16px;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}