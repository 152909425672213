.comment-card {
  background-color: var(--card-background);
  border: 1px solid var(--border-color);
  border-radius: 8px;
  padding: 16px;
  margin: 8px 0;
  width: 100%;
  box-sizing: border-box;
}

.comment-card:hover {
  background-color: #E8F4F1;
}

.comment-icon {
  border-radius: 50%;
  margin-right: 16px;
  width: 50px;
  height: 50px;
  object-fit: cover;
}

.comment-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
}

.comment-info {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.comment-meta {
  display: flex;
  flex-direction: column; /* ニックネームと日付を縦に並べる */
}

.comment-author {
  font-weight: bold;
  color: var(--text-color);
}

.comment-date {
  font-size: var(--meta-text);
  color: var(--light-text-color);
}

.comment-content {
  font-size: var(--body-text);
  line-height: 1.5;
  color: var(--text-color);
  margin-bottom: 0.5rem;
  white-space: pre-line;
  word-break: break-all;
}

.comment-actions {
  display: flex;
  gap: 1rem;
}

.action-button {
  display: flex;
  align-items: center;
  gap: 0.3rem;
  background: none;
  border: 1px solid var(--primary-color);
  border-radius: 20px;
  color: var(--primary-color);
  cursor: pointer;
  padding: 8px 12px;
  transition: color 0.3s ease, background-color 0.3s ease;
}

.action-button:hover {
  background-color: var(--primary-color);
  color: white;
}

.comment-report-flag {
  cursor: pointer;
  color: var(--primary-color);
  transition: color 0.3s;
}

.comment-report-flag:hover {
  color: var(--hover-color-primary);
}

.loading {
  text-align: center;
  font-size: 1.2rem;
  color: var(--light-text-color);
  margin-top: 2rem;
}

.replying-to {
  background-color: #f0f0f0;
  border-radius: 4px;
  padding: 10px;
  margin-bottom: 10px;
  font-size: 0.9em;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.replying-to button {
  background: none;
  border: none;
  color: #666;
  cursor: pointer;
  text-decoration: underline;
}

.no-comments {
  text-align: center;
  padding: 20px;
  background-color: transparent;
  margin-top: 20px;
}

.no-comments img {
  max-width: 200px;
  margin-bottom: 20px;
}

.no-comments p {
  font-size: var(--body-text);
  color: var(--text-color);
  margin: 0;
}

@media (max-width: 768px) {
  .thread-detail {
    padding: 1rem;
    max-width: 100%;
  }

  .thread-title {
    font-size: var(--heading-medium);
  }

  .comment.level-1 {
    margin-left: 1rem;
  }
}